export class Timetable {
  private weekday: number;
  private type: string;
  private zone: string;
  private open: number;
  private close: number;
  private interval: number;
  
  constructor(json: any[]) {
    this.weekday = +json['weekday'];
    this.type = json['type'];
    this.open = +json['open'];
    this.close = +json['close'];
    this.interval = +json['interval'];
  }

  public getWeekday(): number {
    return this.weekday;
  }

  public getType(): string {
    return this.type;
  }

  public getOpen(): number {
    return this.open;
  }

  public getClose(): number {
    return this.close;
  }

  public getInterval(): number {
    return this.interval;
  }
}