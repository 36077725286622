import { IdItem } from '../../shared/selectable/idItem';
import { DateFormat, DateUtils } from '../../shared/utils/dateUtils';

export enum ReserveStatus {
  Requested,
  Verified,
  Seated,
  NoShow,
  Canceled,
  Blocked,
  Unknown
}

function parseAttributes(input: string): any[] {
  try {
    return JSON.parse(input);
  }
  catch {
    const regex = /^((Turno de comidas ([0-9]{2}):[0-9]{2}, )?(Turno de cenas ([0-9]{2}):[0-9]{2}, )?([\s\S]*) : )?({.*})$/;
    const result = regex.exec(input);
    if (result !== null) {
      let attributes = JSON.parse(result[7]);
      attributes["comments"] = result[6];
      if (result[5] != '') {
        attributes["kitchen2"] = result[5];
      }
      if (result[3] != '') {
        attributes["kitchen1"] = result[3];
      }
      return attributes;
    }
    return [];
  }
}

export class Reserve extends IdItem {
  private _code: string;
  private _version: number;
  private _userId: number;
  private _zone: string;
  private _groupId: number;
  private _size: number;
  private _status: string;
  private _updated: string;
  private _date: string;
  private _start: number;
  private _duration: number;
  private _arrived: number;
  private _left: number;
  private _isAssigned: boolean;
  private _attributes: any[];
  private _ticketId: number;

  constructor(json: any[]) {
    super(+json['id']);
    this._code = json['code'];
    this._version = +json['version'];
    this._userId = +json['user_id'];
    this._zone = json['zone'];
    this._groupId = +json['group_id'];
    this._size = +json['size'];
    this._status = json['status'];
    this._updated = json['updated'];
    this._date = json['date'];
    this._start = +json['start'];
    this._duration = +json['duration'];
    this._arrived = +json['arrived'];
    this._left = +json['left'];
    this._isAssigned = json['assigned'] === '1';
    this._attributes = parseAttributes(json['comments']);
    this._ticketId = +json['ticket_id'];
  }

  // Instance public methods
  get code(): string {
    return this._code;
  }

  get version(): number {
    return this._version;
  }

  get userId(): number {
    return this._userId;
  }

  get zone(): string {
    return this._zone;
  }

  get groupId(): number {
    return this._groupId;
  }

  get size(): number {
    return this._size;
  }

  get status(): ReserveStatus {
    switch (this._status) {
    case 'requested':
      return ReserveStatus.Requested;
    case 'confirmed':
    case 'verified':
      return ReserveStatus.Verified;
    case 'seated':
      return ReserveStatus.Seated;
    case 'canceled':
      return ReserveStatus.Canceled;
    case 'noshow':
      return ReserveStatus.NoShow;
    case 'blocked':
      return ReserveStatus.Blocked;
    default:
      return ReserveStatus.Unknown;
    }
  }

  get updated(): string {
    return this._updated;
  }

  get date(): string {
    return this._date;
  }

  get start(): number {
    return this._start;
  }

  get duration(): number {
    return this._duration;
  }

  get arrived(): number {
    return this._arrived;
  }

  get left(): number {
    return this._left;
  }

  get isAssigned(): boolean {
    return this._isAssigned;
  }

  get name(): string {
    return this._attributes['name'] != undefined ? this._attributes['name'] : null;
  }

  get phone(): string {
    return this._attributes['phone'] != undefined ? this._attributes['phone'] : '';
  }

  get comments(): string {
    return this._attributes['comments'] != undefined ? this._attributes['comments'] : '';
  }

  get attributes(): string[] {
    return this._attributes;
  }

  // Computed fields
  get begin(): number {
    return this._arrived > 0 ? this._arrived : this._start;
  }

  get end(): number {
    return this._left > 0 ? this._left : this._start + this._duration;
  }

  //TODO: replace this for attributes[x] calls
  get kids(): boolean | number {
    if (this._attributes['numkids'] != undefined) {
      return +this._attributes['numkids'];
    }
    return this._attributes['with_kids'] == true;
  }

  get kitchen1(): string {
    return this._attributes['kitchen1'] != undefined ? this._attributes['kitchen1'] : '';
  }

  get kitchen2(): string {
    return this._attributes['kitchen2'] != undefined ? this._attributes['kitchen2'] : '';
  }

  get ticketId(): number {
    return this._ticketId;
  }
  
  get tronas(): string {
    return this._attributes['tronas'] != undefined ? this._attributes['tronas'] : '';
  }

  getMinTotal(): number {
    // TODO: remove trailing calculation once reserves include minTotal attribute.
    return this._attributes['minTotal'] != undefined ? this._attributes['minTotal'] : ((this.kitchen1 != '' ? 1 : 0) + (this.kitchen2 != '' ? 1: 0)) * 15 * this.size;
  }

  getSummary(): string {
    return 'el ' + DateUtils.date2LocalString(DateUtils.parseMysqlDate(this.date), DateFormat.Long) + 
          ' de ' + DateUtils.formatTime(this.start) + ' a ' + DateUtils.formatTime(this.end) +
          ' para ' + this.size + ' personas';
  }
}

export class ReserveMap extends Map<number, Reserve> {
  constructor(reserves: Reserve[] = []) {
    super(reserves.map(r => [r.id, r]));
  } 
};