import { Endpoint, Request, Response, SuccessResponse } from './shared/endpoint/endpoint';
import { DailyOccupation, HourlyOccupation, Reserve } from '../../../tpv/src/app/reserves/_data/_data'; // TODO: import this through shared folder

export class GetTranslationsRequest extends Request {
  
  constructor(lang: string ) {
    super('getTranslations', lang);
  }
}

export class LoginRequest extends Request {
  private email;
  private password;

  constructor(email: string, password: string) {
    super('login');
    this.email = email;
    this.password = password;
  }
}

export class LoginResponse extends Response {
  auth_token: string;

  constructor(json: any[]) {
    super(json);
    this.auth_token = json['auth_token'];
  }
}

export class RegisterRequest extends Request {
  private email: string;
  private name: string;
  private lastname: string;
  private phone: string;

  constructor(email: string, name: string, lastname: string, phone: string) {
    super('register');
    this.email = email;
    this.name = name;
    this.lastname = lastname;
    this.phone = phone;
  }
}

export class GetReserveRequest extends Request {
  private code: string;
  private versionChar: string;

  constructor(code: string, version: string = null) {
    super('getReserve');
    this.code = code;
    this.versionChar = version;
  }
}

export class GetUserReservesRequest extends Request {
  private page: number;
  private filter: string;

  constructor(page: number, filter: string) {
    super('getUserReserves');
    this.page = page;
    this.filter = filter;
  }
}

export class CheckCodeRequest extends Request {
  private code: string;

  constructor(code: string) {
    super('checkCode');
    this.code = code;
  }
}

export class RenewPasswordRequest extends Request {
  private email;

  constructor(email: string) {
    super('renewPassword');
    this.email = email;
  }
}

export class UpdatePasswordRequest extends Request {
  private code;
  private newPassword;

  constructor(code: string, newPassword: string) {
    super('updatePassword');
    this.code = code;
    this.newPassword = newPassword;
  }
}

export class UpdateUserRequest extends Request {
  private name: string;
  private lastname: string;
  private phone: string;

  constructor(name: string, lastname: string, phone: string) {
    super('updateUser');
    this.name = name;
    this.lastname = lastname;
    this.phone = phone;
  }
}

export class DeleteAccountRequest extends Request {
  
  constructor() {
    super('deleteAccount');
  }
}

export class GetDailyOccupationRequest extends Request {
  private storeId = 1;
  private zone: string;
  private since: string;
  private until: string;

  constructor(zone: string, since: string, until: string) {
    super('getDailyOccupation');
    this.zone = zone;
    this.since = since;
    this.until = until;
  }
}

export class GetHourlyOccupationRequest extends Request {
  private storeId = 1;
  private zone: string;
  private date: string;
  private replaceCode: string;

  constructor(lang: string, zone: string, date: string, replaceCode: string) {
    super('getHourlyOccupation', lang);
    this.zone = zone;
    this.date = date;
    this.replaceCode = replaceCode;
  }
}

export class RequestReserveRequest extends Request {
  private storeId = 1;
  private zone: string;
  private date: string;
  private start: number;
  private duration: number;
  private size: number;
  private attributes: string;
  private code: string;
  private versionChar: string;

  constructor(zone: string, date: string, start: number, duration: number, size: number, attributes: string, urlCode: string) {
    super('requestReserve');
    this.zone = zone;
    this.date = date.replace('/', '-').replace('/', '-');
    this.start = start;
    this.duration = duration;
    this.size = size;
    this.attributes = attributes;
    this.code = urlCode ? urlCode.substring(1) : null;
    this.versionChar = urlCode ? urlCode[0] : null;
  }
}

export class AssignReserveRequest extends Request {
  private code: string;

  constructor(code: string) {
    super('assignReserve');
    this.code = code;
  }
}

export class ConfirmReserveRequest extends Request {
  private code: string;

  constructor(code: string) {
    super('confirmReserve');
    this.code = code;
  }
}

export class CancelReserveRequest extends Request {
  private code: string;
  private version: number;
  private versionChar: string;

  constructor(code: string, version: number|string) {
    super('cancelReserve');
    this.code = code;
    if (typeof version == 'number') {
      this.version = version;
    }
    else if (typeof version == 'string') {
      this.versionChar = version;
    }
  }
}

export class TranslationsResponse extends Response {
  translations: Map<string, string>;

  constructor(json: any) {
    super(json);
    this.translations = new Map();
    for (const translation of json['translations']) {
      this.translations.set(translation['id'], translation['translation']);
    }
  }
}

export class DailyOccupationResponse extends Response {
  occupation: DailyOccupation;
  
  constructor(json: any[]) {
    super(json);
    this.occupation = new DailyOccupation(json["occupation"]);
  }
}

export class HourlyOccupationResponse extends Response {
  occupation: HourlyOccupation;

  constructor(json: any[]) {
    super(json);
    this.occupation = new HourlyOccupation(json["occupation"]);
  }
}

export class OccupationMatrixResponse extends Response {
  matrix: any[];
  errors: string[];

  constructor(json: any[]) {
    super(json);
    this.matrix = json['matrix'];
    this.errors = json['errors'];
  }
}

export class ReserveResponse extends Response {
  reserve: Reserve;

  constructor(json: any) {
    super(json);
    this.reserve = new Reserve(json['reserve']);
  }
}

export class RequestReserveResponse extends Response {
  codes: string[];

  constructor(json: any[]) {
    super(json);
    this.codes = json['codes'];
  }
}

export class UserReservesResponse extends Response {
  reserves: Reserve[];
  count: number
  pageSize: number;

  constructor(json: any[]) {
    super(json);
    this.reserves = [];
    for (const reserve of json['reserves']) {
      this.reserves.push(new Reserve(reserve));
    }
    this.count = +json['count'];
    this.pageSize = +json['page_size'];
  }
}

export class WebEndpoint extends Endpoint {

  private endpoint = '/api/v3/users/';
  private lang: string = 'es';

  public s() {
    super.s();
  }

  public t(i, t) {
    super.t(i, t);
  }
  
  public setLang(lang: string) {
    this.lang = lang;
  }

  public getTranslations(onResponse: (response: TranslationsResponse) => void, onError: (code: number, message: string) => void) {
    super.sendRequest<GetTranslationsRequest, TranslationsResponse>(this.endpoint, new GetTranslationsRequest(this.lang), TranslationsResponse, r => onResponse(r), onError);
  }

  /**** USER ****/
  login(request: LoginRequest, onResponse: (response: LoginResponse) => void, onError: (code: number, message: string) => void) {
    super.sendRequest<LoginRequest, LoginResponse>(this.endpoint, request, LoginResponse, onResponse, onError);
  }

  register(request: RegisterRequest, onResponse: () => void, onError: (code: number, message: string) => void) {
    super.sendRequest<RegisterRequest, SuccessResponse>(this.endpoint, request, SuccessResponse, onResponse, onError);
  }

  checkCode(request: CheckCodeRequest, onResponse: () => void, onError: (code: number, message: string) => void) {
    super.sendRequest<CheckCodeRequest, SuccessResponse>(this.endpoint, request, SuccessResponse, onResponse, onError);
  }

  renewPassword(request: RenewPasswordRequest, onResponse: () => void, onError: (code: number, message: string) => void) {
    super.sendRequest<RenewPasswordRequest, SuccessResponse>(this.endpoint, request, SuccessResponse, onResponse, onError);
  }

  updatePassword(request: UpdatePasswordRequest, onResponse: () => void, onError: (code: number, message: string) => void) {
    super.sendRequest<UpdatePasswordRequest, SuccessResponse>(this.endpoint, request, SuccessResponse, onResponse, onError);
  }

  updateUser(request: UpdateUserRequest, onResponse: (response: LoginResponse) => void, onError: (code: number, message: string) => void) {   // Update user will generate a new token. Treat response as login response
    super.sendRequest<UpdateUserRequest, LoginResponse>(this.endpoint, request, LoginResponse, onResponse, onError);
  }

  deleteAccount(request: DeleteAccountRequest, onResponse: () => void, onError: (code: number, message: string) => void) {
    super.sendRequest<DeleteAccountRequest, SuccessResponse>(this.endpoint, request, SuccessResponse, onResponse, onError);
  }

  /**** RESERVES ****/
  
  getDailyOccupation(request: GetDailyOccupationRequest, onResponse: (response: DailyOccupationResponse) => void = null,
      onError: (code: number, message: string) => void = null) {
    super.sendRequest<GetDailyOccupationRequest, DailyOccupationResponse>(this.endpoint, request, DailyOccupationResponse, onResponse, onError);
  }
    
  getHourlyOccupation(request: GetHourlyOccupationRequest, onResponse: (response: HourlyOccupationResponse) => void = null,
      onError: (code: number, message: string) => void = null) {
    super.sendRequest<GetHourlyOccupationRequest, HourlyOccupationResponse>(this.endpoint, request, HourlyOccupationResponse, onResponse, onError);
  }
    
  reserveRequest(request: RequestReserveRequest, onResponse: (response: RequestReserveResponse) => void = null, onError: (code: number, message: string) => void = null) {
    super.sendRequest<RequestReserveRequest, RequestReserveResponse>(this.endpoint, request, RequestReserveResponse, onResponse, onError);
  }

  getReserve(request: GetReserveRequest, onResponse: (response: ReserveResponse) => void = null, onError: (code: number, message: string) => void = null) {
    super.sendRequest<GetReserveRequest, ReserveResponse>(this.endpoint, request, ReserveResponse, onResponse, onError);
  }

  getReserves(request: GetUserReservesRequest, onResponse: (response: UserReservesResponse) => void = null, onError: (code: number, message: string) => void = null) {
    super.sendRequest<GetUserReservesRequest, UserReservesResponse>(this.endpoint, request, UserReservesResponse, onResponse, onError);
  }

  assignReserve(request: AssignReserveRequest, onResponse: (response: ReserveResponse) => void = null, onError: (code: number, message: string) => void = null) {
    super.sendRequest<AssignReserveRequest, ReserveResponse>(this.endpoint, request, ReserveResponse, onResponse, onError);
  }

  confirmReserve(request: ConfirmReserveRequest, onResponse: (response: ReserveResponse) => void = null, onError: (code: number, message: string) => void = null) {
    super.sendRequest<ConfirmReserveRequest, ReserveResponse>(this.endpoint, request, ReserveResponse, onResponse, onError);
  }

  cancelReserve(request: CancelReserveRequest, onResponse: (response: ReserveResponse) => void = null, onError: (code: number, message: string) => void = null) {
    super.sendRequest<CancelReserveRequest, ReserveResponse>(this.endpoint, request, ReserveResponse, onResponse, onError);
  }
}