import { Component, Input, Output, EventEmitter } from '@angular/core';

import { WebService } from '../web.service';

import { DateUtils, DateFormat } from '../shared/utils/dateUtils';
import { Reserve, ReserveStatus } from '../../../../tpv/src/app/reserves/_data/_data';

import { DialogComponent } from '../shared/dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-reserve-card',
  templateUrl: './web-reserve-card.component.html',
  styleUrl: './web-reserve-card.component.css',
  host: {'[class.warning]': 'warning', '[class.expanded]': 'expanded || expanded == null', '[class.fixed]': 'expanded === null', '[class.canceled]': 'reserve?.status == ReserveStatus.Canceled'}
})
export class WebReserveCardComponent {
  private ws = WebService.getInstance();
  @Input() expanded: boolean;
  @Input() warning: string;
  @Output() toggle = new EventEmitter();
  @Output() refresh = new EventEmitter();
  protected canUpdate: boolean;
  protected isFutureDate: boolean;
  protected ReserveStatus = ReserveStatus; // expose ReserveStatus to host condition
  
  private _reserve: Reserve;
  @Input() set reserve(reserve: Reserve) {
    this._reserve = reserve;
    const date = DateUtils.date2IsoString(DateUtils.parseMysqlDate(reserve.date));
    const today = DateUtils.getTodayString();
    this.canUpdate = (reserve.status == ReserveStatus.Verified) && date >= today;
    this.isFutureDate = date > today;
  }

  constructor(private router: Router) {}

  public get reserve(): Reserve {
    return this._reserve;
  }

  protected onClickInternal(event: Event) {
    event ? event.stopPropagation() : null;
    this.toggle.emit();
  }

  protected update() {
    window.parent.postMessage({'url': './book?U' + this._reserve.version + this._reserve.code }, "*");
  }

  protected cancel() {
    DialogComponent.showDialog(this.getTranslation('USER_RC_CANCEL_TITLE'), this.getTranslation('USER_RC_CANCEL_MSG') + this.reserve.getSummary())
      .then(_ => this.ws.cancelReserve(this.reserve.code, this.reserve.version,
        r => { DialogComponent.showInfoDialog(this.getTranslation('USER_RA_CANCELED_TITLE'), this.getTranslation('USER_RA_CANCELED_MSG') + r.getSummary()); this.refresh.emit(Math.random()); }),
        () => { DialogComponent.showError(this.getTranslation('USER_RA_UNKNOWN_ERR'))});
  }

  protected formatDate(date: string): string {
    return DateUtils.date2LocalString(DateUtils.parseMysqlDate(date), DateFormat.Long);
  }

  protected formatTime(time: number): string {
    return DateUtils.formatTime(time);
  }

  protected formatDuration(duration: number): string {
    return DateUtils.formatDuration(duration);
  }

  protected translateStatus(status: ReserveStatus): string {
    switch (status) {
    case ReserveStatus.Requested:
      return 'Solicitada';
    case ReserveStatus.Verified:
      return 'Verificada';
    case ReserveStatus.Seated:
      return 'Sentada';
    case ReserveStatus.Canceled:
      return 'Cancelada';
    case ReserveStatus.NoShow:
      return 'No aparece';
    case ReserveStatus.Blocked:
      return 'Bloqueada';
    default:
      return '???';
    }
  }

  protected getTranslation(tag: string): string {
    return this.ws.translate(tag);
  }
}
