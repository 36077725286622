import { Component, OnInit } from '@angular/core';

import { WebService } from '../web.service';

import { Reserve, ReserveStatus } from '../../../../tpv/src/app/reserves/_data/_data';

@Component({
  selector: 'app-web-reserves',
  templateUrl: './web-reserves.component.html',
  styleUrl: './web-reserves.component.css'
})
export class WebReservesComponent implements OnInit {
  private ws = WebService.getInstance();
  protected loggedIn: boolean = false;
  protected nextReserves: Reserve[];
  protected nextCount: number = 0;
  protected prevReserves: Reserve[];
  protected prevCount: number = 0;
  protected pageSize: number = 20;
  protected currentNextPage: number = 0;
  protected currentPrevPage: number = 0;
  protected selectedReserveCode: string = '';

  public ngOnInit(): void {
    this.loggedIn = this.ws.getTokenPayload() != null;  
    if (this.loggedIn) {
      this.getPage('1', true);
      this.getPage('1', false);
    }
  }

  protected getPage(page: string, future: boolean) {
    let currentPage = future == true ? this.currentNextPage : this.currentPrevPage;
    let nextPage: number;
    let force = false;
    switch (page) {
    case '<<':
      nextPage = 0;
      break;
    case '<':
      nextPage = currentPage - 1;
      break;
    case '>':
      nextPage = currentPage + 1;
      break;
    case '>>':
      nextPage = Math.ceil((future == true ? this.nextCount : this.prevCount) / this.pageSize - Number.EPSILON);
      break;
    default:
      nextPage = parseInt(page);
      force = true;
      break;
    }
    nextPage = Math.max(Math.min(nextPage, Math.ceil((future == true ? this.nextCount : this.prevCount) / this.pageSize - Number.EPSILON)), 1);
    if (force || nextPage != currentPage) {
      if (future == true) { 
        this.currentNextPage = nextPage;
        this.ws.getUserReserves(nextPage - 1, 'future',
          (r, c, s) => { this.nextReserves = r; this.nextCount = c; this.pageSize = s; });
      }
      else {
        this.currentPrevPage = nextPage;
        this.ws.getUserReserves(nextPage - 1, 'past',
          (r, c, s) => { this.prevReserves = r; this.prevCount = c; this.pageSize = s; });
      }
    }
  }

  protected getPageList(future: boolean): string[] {

    let totalPages = Math.ceil((future == true ? this.nextCount : this.prevCount) / this.pageSize);
    let currentPage = future == true ? this.currentNextPage : this.currentPrevPage;
    let pages = [];
    if (totalPages < 2) {
      return [];
    }
    if (totalPages < 5) {
      return ['<<', '<'].concat(Array.from(Array(totalPages).keys()).map(i => String(i + 1)), ['>', '>>']);
    }
    
    let start = Math.max(currentPage - 3, 0);
    let end = Math.min(start + 5, totalPages);
    start = Math.max(end - 5, 0); // This is meant to push start down if we were already on the last 5 pages
    pages 
    return ['<<', '<'].concat(Array.from(Array(end - start).keys()).map(i => String(1 + i + start)), ['>', '>>']);
  }

  

  protected showWarning(reserve: Reserve) {
    return reserve.status != ReserveStatus.Canceled && this.nextReserves.filter(r => r.date == reserve.date && r.status != ReserveStatus.Canceled).length > 1;
  }

  protected selectReserve(reserve: Reserve) {
    this.selectedReserveCode = reserve.code == this.selectedReserveCode ? null : reserve.code;
  }

  protected goToReserve() {
    window.parent.postMessage({'url': './book'}, "*");
  }

  protected reload() {
    this.ngOnInit();
  }

  protected getTranslation(tag: string): string {
    return this.ws.translate(tag);
  }
}
